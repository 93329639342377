import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect } from "react";
import { Date_Format } from "../../../../config";
import i18n from "../../../../locale/i18n";
import RobotoBlack from "../../../../assets/fonts/Roboto-Black.ttf";
import RobotoBold from "../../../../assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "../../../../assets/fonts/Roboto-Regular.ttf";
import { styles } from "./PdfStyle";
import ClearSpeedLogo from "../../../../assets/images/ClearspeedLogo.png";
import { FooterText } from "./FooterText";

const NOT_FOUND = "--";

const PdfDocument = (props) => {
  const imgElement = React.useRef(null);

  const registerFont = () => {
    Font.register({
      family: "Roboto",
      fonts: [
        { src: RobotoRegular, fontWeight: 400 }, // font-style: normal, font-weight: normal
        { src: RobotoBold, fontWeight: 700 },
        { src: RobotoBlack, fontWeight: 900 },
      ],
    });
  };

  useEffect(() => {
    registerFont();
  }, []);

  const {
    result,
    questionnaireType,
    questionList,
    selectedTimeZoneOffset,
    pdfLogo,
  } = props;

  const getImage = () => {
    let imageLogo = "";
    if (pdfLogo) {
      let b64Data = pdfLogo;
      let contentType = "image/png";
      let blob = b64toBlob(b64Data, contentType);
      imageLogo = URL.createObjectURL(blob);
    }

    let logo = pdfLogo ? (
      <Image
        style={styles.logoImage}
        src={imageLogo}
        alt="Logo"
        ref={imgElement}
        onLoad={() =>
          console.log("imgElement", imgElement.current.naturalHeight)
        }
      />
    ) : (
      <Image style={styles.logoImage} src={ClearSpeedLogo} alt="Logo" />
    );
    return logo;
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const getDateInFormat = () => {
    if (result.date)
      return moment(result.date)
        .utcOffset(selectedTimeZoneOffset)
        .format(Date_Format);
    return NOT_FOUND;
  };

  const getMaskedAccessCode = (num) => {
    if (num) {
      const numb = num.toString();
      let number = `${numb.substring(0, 3)}-${numb.substring(
        3,
        6
      )}-${numb.substring(6, numb.length)}`;
      return number;
    }
  };

  const getRiskIcon = (sequence) => {
    let resultValue = "";
    if (result.interviewQuestionsResult) {
      result.interviewQuestionsResult.map((resultDetail, index) => {
        if (resultDetail.sequence == sequence) {
          resultValue = resultDetail.result;
        }
      });
    }

    switch (resultValue) {
      case "LR":
        return { ...styles.lowRiskIcon, ...styles.tableIconWidth };
      case "R":
        return { ...styles.highRiskIcon, ...styles.tableIconWidth };
      case "G":
        return { ...styles.averageRiskIcon, ...styles.tableIconWidth };
      case "AR":
        return { ...styles.averageRiskIcon, ...styles.tableIconWidth };
      case "PR":
        return { ...styles.potentialRiskIcon, ...styles.tableIconWidth };
      case "HR":
        return { ...styles.highRiskIcon, ...styles.tableIconWidth };
      case "NC":
        return { ...styles.notCompletedIcon, ...styles.tableIconWidth };
      case "DC":
        return { ...styles.disconnectedIcon, ...styles.tableIconWidth };
      case "CM":
        return { ...styles.counterMeasureIcon, ...styles.tableIconWidth };
      case "AD":
        return { ...styles.admissionIcon, ...styles.tableIconWidth };
      case "NP":
        return { ...styles.nonResponsiveParticipant, ...styles.tableIconWidth };
      default:
        return resultValue;
    }
  };

  const getRiskLabel = (sequence) => {
    let resultValue = "";
    if (result.interviewQuestionsResult) {
      result.interviewQuestionsResult.map((resultDetail, index) => {
        if (resultDetail.sequence == sequence) {
          resultValue = resultDetail.result;
        }
      });
    }

    return resultValue;
  };

  const getRiskLabelTrans = (sequence) => {
    let resultValue = "";
    if (result.interviewQuestionsResult) {
      result.interviewQuestionsResult.map((resultDetail, index) => {
        if (resultDetail.sequence == sequence) {
          resultValue = resultDetail.riskLevelTrans;
        }
      });
    }
    return resultValue;
  };

  const getTranscription = (questionNumber) => {
    let resultValue = "";
    if (result.interviewQuestionsResult) {
      result.interviewQuestionsResult.map((resultDetail, index) => {
        if (resultDetail.sequence == questionNumber) {
          resultValue = resultDetail;
        }
      });
    }

    return resultValue.transciption ? (
      <>
        <Text style={styles.questionName}>{i18n.t("Transcription")}:</Text>
        <Text style={styles.questionTC}>{resultValue.transciption}</Text>
      </>
    ) : (
      <Text></Text>
    );
  };

  const filteredQuestions = (questions) => {
    const filterQuestion = questions.filter((question) => {
      return !(
        question.isPertinant === false && question.expectedAnswer !== "Narr"
      );
    });
    return filterQuestion;
  };

  const getQuestionTable1 = (questionList) => {
    const updatedQuestion = filteredQuestions(questionList);
    if (updatedQuestion) {
      return updatedQuestion.map((questionDetail, index) => {
        const backgroundColor = index % 2 !== 0 && "#F2F2F2";
        // const getTrans = getRiskLabelTrans(questionDetail.questionNumber);
        // const getFlag = findTemplateRiskLevelFlag(
        //   flagsOptionsData,
        //   "questions",
        //   getTrans
        // );
        return (
          <View
            style={{
              ...styles.tableRow,
              backgroundColor: backgroundColor,
              ...styles.questionTableContainer,
            }}
            key={questionDetail.questionNumber}
          >
            <View style={styles.tableCol80}>
              <Text style={styles.questionTC}>
                {/* {questionDetail.questionNumber}
                {". "} */}
                {questionDetail.questionText}
              </Text>
              {getTranscription(questionDetail.questionNumber)}
            </View>
            <View style={{ ...styles.resultDetail, ...styles.resultLabel }}>
              <Text style={getRiskIcon(questionDetail.questionNumber)}></Text>
              <Text style={styles.questionNumber}>
                {getRiskLabel(questionDetail.questionNumber)}
              </Text>
              {/* // Apply condition for icon in PDF  */}
            </View>
          </View>
        );
      });
    } else {
      return (
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableCol80 }}>
            <Text>{i18n.t("No question")}</Text>
          </View>
        </View>
      );
    }
  };

  const getFullRiskCode = (val) => {
    val = val.toUpperCase();
    switch (val) {
      case "LR":
        return i18n.t("Low Risk");
      case "AR":
        return i18n.t("Average Risk");
      case "PR":
        return i18n.t("Potential Risk");
      case "HR":
        return i18n.t("High Risk");
      case "R":
        return i18n.t("RED");
      case "G":
        return i18n.t("GREEN");
      case "CM":
        return i18n.t("Countermeasures");
      case "AD":
        return i18n.t("Admissions");
      case "NC":
        return i18n.t("Non-Compliant");
      case "PD":
        return i18n.t("Pending");
      case "RJ":
        return i18n.t("Rejected");
      case "DC":
        return i18n.t("Disconnected");
      case "NP":
        return i18n.t("Non-Responsive Participant");
      default:
        return "";
    }
  };

  const getColor = (overallEvaluation) => {
    switch (overallEvaluation) {
      case "LR":
        return "#008000";
      case "AR":
        return "#92d050";
      case "PR":
        return "#ffca00";
      case "HR":
        return "#ff0000";
      case "NC":
        return "#bcbcbc";
      case "DC":
        return "#7b7a7a";
      case "CM":
        return "#9481f7";
      case "AD":
        return "#5a7cb7";
      case "NP":
        return "#000000";
      case "R":
        return "#ff0000";
      case "G":
        return "#008000";
    }
  };

  const getBackGroundColor = (overallEvaluation) => {
    switch (overallEvaluation) {
      case "LR":
        return "#b2d6ac";
      case "AR":
        return "#d7efbe";
      case "R":
        return "#f0d1cd";
      case "G":
        return "#b2d6ac";
      case "PR":
        return "#ffefb2";
      case "HR":
        return "#f0d1cd";
      case "NC":
        return "#e1e1e1";
      case "DC":
        return "#acacac";
      case "CM":
        return "#cac0fb";
      case "AD":
        return "#6d8bbf";
      case "NP":
        return "#c9cbce";
    }
  };

  return (
    <Document style={styles.documentContent}>
      <Page style={styles.page} renderMode="canvas">
        <View style={styles.pageContainer}>
          <View style={styles.headerContainer}>
            <View>
              {questionnaireType && (
                <Text style={styles.headerText}>
                  {i18n.t(questionnaireType)}
                </Text>
              )}
            </View>

            <View>{getImage()}</View>
          </View>
          <View
            style={{
              ...styles.overAllEvaluationContainer,
              backgroundColor: getBackGroundColor(result.overallEvaluation),
            }}
          >
            <Text style={styles.overAllRiskLabel}>
              {i18n.t("OVERALL EVALUATION")}
              {":"}
            </Text>
            <Text
              style={{
                ...styles.overAllRisk,
                color: getColor(result.overallEvaluation),
              }}
            >
              {getFullRiskCode(result.overallEvaluation)}
            </Text>
          </View>
          <View style={styles.content}>
            <View
              style={{
                ...styles.table,
                ...styles.font17,
                ...styles.borderA,
              }}
            >
              <View style={{ ...styles.tableRow, ...styles.borderB }}>
                <View
                  style={{
                    ...styles.tableCol,
                    ...styles.borderR,
                  }}
                >
                  <Text style={styles.infoGray}>
                    {i18n.t("Participant / IRN")}{" "}
                  </Text>
                  <Text style={styles.participantDetailsText}>
                    {result.interviewReferenceNumber
                      ? result.interviewReferenceNumber
                      : NOT_FOUND}
                  </Text>
                </View>
                <View style={styles.tableCol70}>
                  <View style={styles.rowContent}>
                    <Text style={styles.infoGray}>
                      {i18n.t("Client Name")}{" "}
                    </Text>
                    <Text style={styles.participantDetailsText}>
                      {result.clientName ? result.clientName : NOT_FOUND}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ ...styles.tableRow, ...styles.borderB }}>
                <View
                  style={{
                    ...styles.tableCol,
                    ...styles.borderR,
                  }}
                >
                  <Text style={styles.infoGray}> {i18n.t("Access Code")} </Text>
                  <Text style={styles.participantDetailsText}>
                    {getMaskedAccessCode(result.accessCode)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol70,
                  }}
                >
                  <View style={styles.rowContent}>
                    <Text
                      style={{
                        ...styles.infoGray,
                        ...styles.labelWidth,
                      }}
                    >
                      {i18n.t("Project Name")}{" "}
                    </Text>
                    <Text
                      style={{
                        ...styles.participantDetailsText,
                        ...styles.projectNameWidth,
                      }}
                    >
                      {result.projectName ? result.projectName : NOT_FOUND}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ ...styles.tableRow }}>
                <View
                  style={{
                    ...styles.tableCol,
                    ...styles.borderR,
                  }}
                >
                  <Text style={styles.infoGray}>{i18n.t("Date")} </Text>
                  <Text style={styles.participantDetailsText}>
                    {getDateInFormat()}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol,
                    ...styles.borderR,
                  }}
                >
                  <Text style={styles.infoGray}>{i18n.t("Location")} </Text>
                  <Text style={styles.participantDetailsText}>
                    {result.location ? result.location : NOT_FOUND}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol,
                  }}
                >
                  <Text style={styles.infoGray}>{i18n.t("Language")} </Text>
                  <Text style={styles.participantDetailsText}>
                    {result.participantLanguage
                      ? result.participantLanguage
                      : NOT_FOUND}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View
                style={{
                  ...styles.table,
                  ...styles.font14,
                }}
              >
                <View
                  style={{
                    ...styles.tableRow,
                    ...styles.infoBlue,
                  }}
                >
                  <View
                    style={{
                      ...styles.tableCol80,
                      ...styles.questionLabel,
                      ...styles.resultColumnColor,
                    }}
                  >
                    <Text>{i18n.t("Questions")}</Text>
                  </View>
                  <View
                    style={{
                      ...styles.resultDetail,
                      ...styles.resultLabel,
                      ...styles.resultColumnColor,
                    }}
                  >
                    <Text>{i18n.t("Results")}</Text>
                  </View>
                </View>
                {questionList ? getQuestionTable1(questionList) : ""}
              </View>
            </View>
          </View>
        </View>
        {questionnaireType && (
          <FooterText questionnaireType={questionnaireType} />
        )}
      </Page>
    </Document>
  );
};

export default PdfDocument;
